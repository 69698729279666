import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import "./Transaction_history.css";
import { TransactionHistoryList } from "../../utils/api.service";
import { TOAST_ERROR } from "../../utils/common.service";
import { useSelector } from "react-redux";

const TransactionHistory = () => {
  const darkMode = useSelector((state) => state.theme.themeValue);

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [Transactions, setTransactions] = useState([]);
 
  const [amount, setAmount] = useState("");
  const [Status, setStatus] = useState("");
  const [date, setDate] = useState("");

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    e.target.type = "text";
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    e.target.type = "text";
  };

  const formatToYYYYMMDD = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);

    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  const formatToReadableDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate.replace(/(\d{2} \w{3}) (\d{4})/, "$1, $2");
  };

  useEffect(() => {
    TransactionHistoryList({})
      .then((response) => {
        if (response.code === "1") {
          setTransactions(response.data);
        } else {
          TOAST_ERROR(response.mess);
        }
      })
      .catch((error) => {
        TOAST_ERROR("An error occurred while fetching data.");
      });
  }, [startDate,endDate]);

  // const handleApplyClick = () => {
  //   const formattedStartDate = formatToYYYYMMDD(startDate);
  //   const formattedEndDate = formatToYYYYMMDD(endDate);

  //   TransactionHistoryList({
  //     start_date: formattedStartDate,
  //     end_date: formattedEndDate,
  //   })
  //     .then((response) => {
  //       console.log("Response in apply transaction history:", response);
  //       if (response.code === "1") {
  //         setTransactions(response.data);
  //       } else {
  //         TOAST_ERROR(response.mess);
  //       }
  //     })
  //     .catch((error) => {
  //       TOAST_ERROR("An error occurred while fetching data.");
  //     });
  // };

  const handleApplyClick = () => {
    const formattedStartDate = startDate ? formatToYYYYMMDD(startDate) : null;
    const formattedEndDate = endDate ? formatToYYYYMMDD(endDate) : null;

    const payload = {};
    if (formattedStartDate) {
      payload.start_date = formattedStartDate;
    }
    if (formattedEndDate) {
      payload.end_date = formattedEndDate;
    }
  
    TransactionHistoryList(payload)
      .then((response) => {
    
        if (response.code === "1") {
          setTransactions(response.data);
        } else {
          TOAST_ERROR(response.mess);
        }
      })
      .catch((error) => {
        TOAST_ERROR("An error occurred while fetching data.");
      });
  };
  

  return (
    <>
      <Layout />
      <div
        className={
          darkMode === "theme-light"
            ? "transaction-history-container1"
            : "transaction-history-container"
        }
      >
        <h1 className="transaction-title">Transaction History</h1>
        <div className="filter-section">
          <div className="date-wrapper">
            <input
              type="text"
              className={`date-input ${
                darkMode === "theme-dark" ? "date-input1" : ""
              }`}
              placeholder="Start Date"
              value={formatToReadableDate(startDate)}
              onChange={(e) => handleStartDate(e)}
              max={endDate}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (!e.target.value) e.target.type = "text";
              }}
            />
          </div>

          <div className="date-wrapper">
            <input
              type="text"
              className={`date-input ${
                darkMode === "theme-dark" ? "date-input1" : ""
              }`}
              placeholder="End Date"
              value={formatToReadableDate(endDate)}
              onChange={(e) => handleEndDate(e)}
              min={startDate || getTodayDate()}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (!e.target.value) e.target.type = "text";
              }}
            />
          </div>
          <button
            className={` ${
              darkMode === "theme-dark" ? "apply-btn1" : "apply-btn"
            }`}
            onClick={handleApplyClick}
          >
            Apply
          </button>
        </div>

        <div
          className={
            darkMode === "theme-dark"
              ? "transactions-list1"
              : "transactions-list"
          }
        >
          {Transactions.length === 0 ? (
            <p>No transactions found.</p>
          ) : (
            Transactions.result.map((transaction, index) => {
              let displayDate = "";
              let displayStatus = "";
              let statusColor = "";
              let arrow = "";
              let displayAmount = "";

              if (displayAmount == null || displayAmount === "") {
                displayAmount = 0.00;
                statusColor = "red";
                arrow = "↑";
            }

              if (transaction.payment_status_label === "Send") {
                displayDate = formatDateTime(transaction.insert_datetime);

                if (transaction.payment_status === "Success") {
                  displayStatus = transaction.payment_status;
                  statusColor = "green";
                  arrow = "↑";
                  displayAmount = transaction.amount;
                } else if (transaction.payment_status === "Failed") {
                  displayStatus = transaction.payment_status;
                  statusColor = "red";
                  arrow = "↑";
                  displayAmount = transaction.amount;
                  
                }
              } else {
                displayDate = formatDateTime(transaction.update_datetime);

                if (transaction.refund_status === "Refund succeeded") {
                  displayStatus = transaction.refund_status;
                  statusColor = "green";
                  arrow = "↓";
                  displayAmount = transaction.refund_amount;

                } else if (transaction.refund_status === "Refund pending") {
                  displayStatus = transaction.refund_status;
                  statusColor = "green";
                  arrow = "↓";
                  displayAmount = transaction.refund_amount;
                  
                } else if (transaction.refund_status === "Refund failed") {
                  displayStatus = transaction.refund_status;
                  statusColor = "red";
                  arrow = "↓";
                  displayAmount = transaction.refund_amount;
                }
              }

              return (
                <div
                  key={index}
                  className={`transaction-card ${transaction.payment_status} ${
                    darkMode === "theme-light"
                      ? "transaction-card-light"
                      : "transaction-card"
                  }`}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    padding: "20px",
                    marginBottom: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="transaction-info">
                    <div className="row">
                      <p
                        className="col-12 col-xl-8 col-lg-8 col-md-10 col-sm-10"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        OrderID: &nbsp;<strong>{transaction.order_id}</strong>
                      </p>
                      <p
                        className="col-12 col-xl-4 col-lg-4 col-md-2 col-sm-2 fw-bold"
                        style={{
                          textAlign: "right",
                          fontSize: "16px",
                          color: statusColor,
                        }}
                      >
                        <span style={{ fontSize: "1.4rem", marginTop: "" }}>
                          {arrow}
                        </span>
                       &nbsp; ₹ {displayAmount}
                      </p>
                    </div>

                    <div className="transaction-status"></div>
                    <p
                      className={` ${
                        darkMode === "theme-dark" ? "paymetid1" : "paymentid"
                      }`}
                    >
                      PaymentID: <span>&nbsp; {transaction.payment_id}</span>
                    </p>
                    <p style={{ fontSize: "14px", color: "#777" }}>
                      {displayDate}
                    </p>
                    <p
                      className="status"
                      style={{
                        fontSize: "16px",
                        color: statusColor,
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      {displayStatus}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
