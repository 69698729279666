import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { createPaymentIntent } from '../../utils/api.service';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';

const CheckoutForm = (props) => {

    // const [options, setOptions] = useState(props.elements !== null ? props.elements._commonOptions : null);

    // 

    const stripe = useStripe();
    const elements = useElements();

    const [clientSecret, setclientSecret] = useState(null);
    // const clientSecret = 'sk_test_51Os0iJSBngbVFgnhRlVf4v4T1yHiOpuFJt1ITOqdX9RKw9MLciK6YZLNBs1WXCc2fUbzYDfC54oW3ak8xnwCXgai00Y3M2N51J';

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // const cardElement = elements.getElement(PaymentElement);

        // if (!cardElement) {
        //     // Handle error, PaymentElement not found
        //     return;
        // }

        // const { error } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement,
        // });

        // if (error) {
        //     
        //     return;
        // }

        let payload = {
            amount: "1099",
            currency: "usd",
            metadata: {
                "txn_id": "001"
            },
        };
        createPaymentIntent(payload).then(async function (response) {

            if (response.code === '1') {
                

                // setclientSecret(response.data.client_secret);
                let client_secret = response.data.client_secret;

                const result = await stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    // payment_method: {
                    //     card: elements.getElement(PaymentElement),
                    //     billing_details: {
                    //         name: 'Jenny Rosen',
                    //     },
                    // },
                    // return_url: "http://localhost:3000/order/123/complete",
                    elements,
                    confirmParams: {
                        return_url: "http://localhost:3000/order/123/complete",
                    },
                });

                

                if (result.error) {
                    // Show error to your customer (for example, payment details incomplete)
                    
                } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                }




            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });

        // const result = await stripe.confirmPayment({
        //     //`Elements` instance that was used to create the Payment Element
        //     elements,
        //     clientSecret,
        //     confirmParams: {
        //         return_url: "http://localhost:3000/order/123/complete",
        //     },
        // });

        // 

        // if (result.error) {
        //     // Show error to your customer (for example, payment details incomplete)
        //     
        // } else {
        //     // Your customer will be redirected to your `return_url`. For some payment
        //     // methods like iDEAL, your customer will be redirected to an intermediate
        //     // site first to authorize the payment, then redirected to the `return_url`.
        // }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button disabled={!stripe || !elements}>Submit</button>
        </form>
    )
};

// export default CheckoutForm;

const InjectedCheckoutForm = () => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <CheckoutForm elements={elements} stripe={stripe} />
            )}
        </ElementsConsumer>
    );
};

export default InjectedCheckoutForm;
