import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NoPage from "./containers/NoPage/NoPage";
import Home from "./containers/Home/Home";
import StaticPage from './containers/StaticPage/StaticPage';
import Dashboard from './containers/Dashboard/Dashboard';
import Notification from './containers/Notification/Notification';
import ManageAds from './containers/ManageAds/ManageAds';
import ManageAds2 from './containers/ManageAds/ManageAds2';
import Win from './containers/Win/Win';
import Win2 from './containers/Win/Win2';
import Winning from './containers/Winning/Winning';
import Winner from './containers/Winner/Winner';
import CouponDetails from './containers/Coupons/CouponDetails';
import ImpressionAnalytics from './containers/ImpressionAnalytics/ImpressionAnalytics';
import Contact from './containers/Contact/Contact';

import AuthProvider from './hoc/AuthProvider';
import PrivateRoute from './hoc/router/PrivateRoute';
import PublicRoute from './hoc/router/PublicRoute';

import GoogleAds from './containers/GoogleAds';
import StripeTest from './containers/StripeTest';
import { ActiveTabProvider } from "./components/common/ActiveTabContext";
import Category from './containers/Win/Category';
import Transaction_history from './components/Transaction_history/Transaction_history';

function App() {

  let finalRoutes = '';

  const routes = (
    <Routes>

      {/* <Route path="/google-ads" element={<GoogleAds />} />
      <Route path="/stripe-test" element={<StripeTest />} /> */}

      {/* Static page routes */}
      <Route path="/terms-and-conditions" element={<StaticPage slug={'terms-conditions'} />} />
      <Route path="/about-us" element={<StaticPage slug={'about-us'} />} />
      <Route path="/faqs" element={<StaticPage slug={'faqs'} />} />
      <Route path="/customer-grievance" element={<StaticPage slug={'customer-grievance'} />} />
      <Route path="/privacy-policy" element={<StaticPage slug={'privacy-policy'} />} />

      <Route path="/contact-us" element={<Contact />} />

      {/* Without Auth Routes */}
      <Route element={<PublicRoute />}>
        <Route path='/' element={<Home />} />
      </Route>

      {/* With Auth Rotues */}
      <Route element={<PrivateRoute />}>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/notification' element={<Notification />} />
        <Route path='/manage-advertisement/stripe-success/advertisement-add' element={<ManageAds2 />} />
        <Route path='/manage-advertisement/stripe-cancel/advertisement-add' element={<ManageAds2 />} />
        <Route path='/manage-advertisement/stripe-success/coupon-add' element={<ManageAds2 />} />
        <Route path='/manage-advertisement/stripe-cancel/coupon-add' element={<ManageAds2 />} />
        <Route path='/manage-advertisement' element={<ManageAds2 />} />
        {/* <Route path='/manage-advertisement2' element={<ManageAds />} /> */}
        <Route path='/win' element={<Win2 />} />
        <Route path='/transcation_history' element={<Transaction_history />} />
        {/* Category */}
        <Route path='/add_category' element={<Category />} />
        <Route path='/winner' element={<Winner />} />
        <Route path='/win2' element={<Win />} />
        <Route path='/winning' element={<Winning />} />
        <Route path='/coupon-details/:id' element={<CouponDetails slug={'coupon-details'} />} />
        <Route path='/my-coupon-details/:id' element={<CouponDetails slug={'my-coupon-details'} />} />

        <Route path='/impression-analytics/:id' element={<ImpressionAnalytics />} />
      </Route>

      {/* 404 - page not found */}
      <Route path="*" element={<NoPage />} />
    </Routes>
  );

  finalRoutes = routes;

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <AuthProvider>
        <ActiveTabProvider>
          {finalRoutes}
        </ActiveTabProvider>

      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
