import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../store/slices/createCouponSlice';
import { useForm, Controller } from 'react-hook-form';
import { openModal as openAdsApprovalModal } from '../../store/slices/adsApprovalSlice';
import { couponCategoryList, createCoupon, getCouponPriceAPI, createStripeCheckoutSession,BrandList,SubCategoryList,CategoryList,CategoryListDeatils,FetchBrandList ,Razorpayoder,RazorpayoderConfirm} from '../../utils/api.service';
import { validateFile, TOAST_SUCCESS, TOAST_ERROR, SWAL_ALERT } from '../../utils/common.service';
import StateCityRow from '../common/StateCityRow';
import moment from 'moment';
import { uploadBase64Image } from '../../utils/aws.service';
import Loader from '../loader/Loader';
import DatePicker from "react-datepicker";
import { openModal as openImageCropper, closeModal as closeImageCropper } from '../../store/slices/imageCropperCouponSlice';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useNavigate } from 'react-router-dom';

const CreateCouponModal = () => {

    const loginUserData = useSelector((state) => state.authUser.loginUserData);
    const isModalOpen = useSelector((state) => state.CreateCoupon.isModalOpen);
    const dispatch = useDispatch();
    const cropperRef = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const handleCrop = () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            const croppedImage = cropperRef.current.cropper.getCroppedCanvas({
                width: 800,
                height: 320
            }).toDataURL('image/jpeg');

            setCroppedImage(croppedImage);
            handleCloseImageCropper()
            setValue("brand_image", croppedImage);
        }
    };
    const isCropperOpen = useSelector((state) => state.imageCropperCoupon.isModalOpen);

    const handleOpenImageCropper = () => {
        dispatch(openImageCropper());
    };

    const handleCloseImageCropper = () => {
        dispatch(closeImageCropper());
    };

    const handleCloseCreateCouponModal = () => {
        setCroppedImage(null)
        dispatch(closeModal());
        setValue("brand_image", '');
    };

    
    const handleOpenAdsApprovalModal = () => {
        handleCloseCreateCouponModal();
        dispatch(openAdsApprovalModal());
    };

    const getMinDate = () => {
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 0, today.getMonth(), today.getDate());

        const year = maxDate.getFullYear();
        const month = (maxDate.getMonth() + 1).toString().padStart(2, '0');
        const day = maxDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [Subcategories,setSubCategories] =useState()
    const [SubcategoryId, setSubCategoryId] = useState([]);
    const [BrandId, setBrandId] = useState([]);
    const [Brandlistt,setBrandListt] =useState()
    const [BrandName,setBrandName] =useState()
    const [couponPrice, setCouponPrice] = useState(0);
    const [fileInput, setFileInput] = useState(false);
    const [fileType, setFileType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileExtension, setFileExtension] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [unitAmount, setUnitAmount] = useState(0);
    const [unitGST, setUnitGST] = useState(0);
    const [unitTotalAmount, setUnitTotalAmount] = useState(0);
    const [imageType,setImageType]=useState("")
    const [files,setFiles]=useState("")
    const handletotalclick = (e) => {
        let total_click = parseInt(getValues("total_click"));
        if (!isNaN(total_click)) {
            setValue("amount", total_click * couponPrice);
            setValue("gst", (getValues("amount") * 18) / 100);
            setValue("total_amount", (getValues("amount") + getValues("gst")));
            // setAdprice(response.data.amount)
            setUnitAmount(getValues("amount"));
            setUnitGST(getValues("gst"));
            setUnitTotalAmount(getValues("total_amount"));
        }
        else {
            setUnitAmount("");
            setUnitGST("");
            setUnitTotalAmount("");
        }
    }
    
    const [cropperInstance, setCropperInstance] = useState(null);


    const handleFileInput = (e) => {
        let file = e.target.files[0];
        setFiles(file)
        let fileType = file.type.split('/').pop(); 
        setImageType(fileType);
        if (file) {
            let fileSize = file.size / 1024
            let ad_type = getValues('ad_type');
            let validateSize = 5120;
            let message = "";
            
            if (fileSize > validateSize) {

                message = "Please select a image smaller than 5MB";

                setError("brand_image", {
                    type: "manual",
                    message: message,
                })
            }
            
            else {

                if (file) {

                    let parts = file.name.split('.');
                    let extension = parts[parts.length - 1];
                    setFileExtension(extension);

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const base64String = e.target.result;
                        setSelectedFile(base64String);


                    };
                    reader.readAsDataURL(file);
                    clearErrors("brand_image");
                    handleOpenImageCropper();
                }
            }
        }
        else {
            let message = "Please select a image ";

            setError("brand_image", {
                type: "manual",
                message: message,
            })
        }
    }



    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
        setError,
        clearErrors,
        trigger,
        formState: { errors, isValid },
    } = useForm();


    const navigate = useNavigate()
    // const onSubmit = async (data) => {

    //     setIsLoading(true);

    //     let file_name = '';

    //     if (isValid && data.brand_image !== null) {
    //         if (data.brand_image !== null) {

    //             file_name = Math.floor(Math.random() * 999999) + '_' + moment().format('X') + '.' + fileExtension;
    //             let filePath = process.env.REACT_APP_AWS_FOLDER_NAME + '/brand-image/' + file_name;
    //             await uploadBase64Image(data.brand_image, filePath)
    //         }

    //         let payload = {
    //             category_id:categoryId,
    //             sub_category_id:SubcategoryId,
    //             brand_id:BrandId,
    //             brand_name:BrandName,
    //             brand_image: file_name,
    //             onelinedescription: data.one_line_desc,
    //             amount: data.amount,
    //             gst: data.gst,
    //             total_amount: data.total_amount,
    //             description: data.description,
    //             websitelink: data.website_url,
    //             total_click: data.total_click,
    //             tutorial: data.tutorial,
    //             start_date: data.start_date,
    //             discount: data.discount
    //         };

    //         // let stripe_payload = {
    //         //     amount: data.total_amount,
    //         //     quantity: 1,
    //         //     currency: "inr",
    //         //     email: loginUserData?.email,
    //         //     product_name:BrandName,
    //         //     success_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-success/coupon-add",
    //         //     cancel_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-cancel/coupon-add"
    //         // };

    //         let razorpay_payload = {
    //             amount: data.total_amount,
    //             quantity: 1,
    //             currency: "inr",
    //             email: loginUserData?.email,
    //             product_name:BrandName,
    //             success_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-success/coupon-add",
    //             cancel_url: process.env.REACT_APP_PUBLIC_URL + "manage-advertisement/stripe-cancel/coupon-add"
    //         };
            
    //         // Razorpayoder
    //         Razorpayoder(razorpay_payload).then(async function (razorpay_response) {
    //             console.log('razorpay_response:@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ', razorpay_response);


    //             if (razorpay_response.code === '1') {
    //                 payload.razorpay_order_id = razorpay_response.data.id;
                    
    //                 createCoupon(payload).then(function (response) {
    //                     setIsLoading(false);
    //                     if (response.code === '1') {
    //                         initPayment(razorpay_response.data);
    //                         window.location.href = razorpay_response.data.url;
    //                     } else if (response.code !== '0') {
    //                         TOAST_ERROR(response.message);
    //                     }
    //                 }).catch(function (error) {
    //                     setIsLoading(false);
                        
    //                 });

    //             } else if (razorpay_response.code !== '0') {
    //                 TOAST_ERROR(razorpay_response.message);
    //             }
    //         }).catch(function (error) {
    //             setIsLoading(false);
                
    //         });

           

    //     }

    // };

    // const onSubmit = async (data) => {
    //     setIsLoading(true);
    
    //     try {
    //         let file_name = '';
    

    //         if (isValid && data.brand_image) {
    //             file_name = `${Math.floor(Math.random() * 999999)}_${moment().format('X')}.${fileExtension}`;
    //             const filePath = `${process.env.REACT_APP_AWS_FOLDER_NAME}/brand-image/${file_name}`;
    //             await (data.brand_image, filePath);
    //         }
    
    //         const payload = {
    //             category_id: categoryId,
    //             sub_category_id: SubcategoryId,
    //             brand_id: BrandId,
    //             brand_name: BrandName,
    //             brand_image: file_name,
    //             onelinedescription: data.one_line_desc,
    //             amount: data.amount,
    //             gst: data.gst,
    //             total_amount: data.total_amount,
    //             description: data.description,
    //             websitelink: data.website_url,
    //             total_click: data.total_click,
    //             tutorial: data.tutorial,
    //             start_date: data.start_date,
    //             discount: data.discount,
    //         };
    
    //         const razorpayPayload = {
    //             amount: data.total_amount * 100,
    //             currency: "INR",
    //             email: loginUserData?.email,
    //             product_name: BrandName,
    //             success_url: `${process.env.REACT_APP_PUBLIC_URL}/manage-advertisement/stripe-success/coupon-add`,
    //             cancel_url: `${process.env.REACT_APP_PUBLIC_URL}/manage-advertisement/stripe-cancel/coupon-add`,
    //         };
    
    //         const razorpayResponse = await Razorpayoder(razorpayPayload);
    
    //         if (razorpayResponse.code === '1') {
      
    //             payload.razorpay_order_id = razorpayResponse.data.id;
    
    //             const couponResponse = await createCoupon(payload);
    //             setIsLoading(false);
    
    //             if (couponResponse.code === '1') {
    
    //                 initPayment(razorpayResponse.data);
    //             } else {
    //                 TOAST_ERROR(couponResponse.message);
    //             }
    //         } else {
    //             TOAST_ERROR(razorpayResponse.message);
    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         setIsLoading(false);
    //     }
    // };

    // const initPayment = (data) => {
    //     const options = {

    //         key: "rzp_test_PpvhJCWg7W0eyD",
    //         amount: data.amount,
    //         currency: data.currency,
    //         order_id: data.id,
    //         handler: async (response) => {
    //             try {
    //                 const verifyResponse = await RazorpayoderConfirm(response);
    //                 navigate('/dashboard')
    //             } catch (error) {
    //                 console.log("Payment verification failed:", error);
    //             }
    //         },
    //         theme: {
    //             color: "#3399cc",
    //         },
    //     };
    
    //     const rzp1 = new window.Razorpay(options);
    //     rzp1.open();
    //     navigate('/dashboard')
    // };


    const onSubmit = async (data) => {
        setIsLoading(true);
    
        try {
             let    uploadFileName =''
            if (isValid && data.brand_image) {
              
                const filePath = "brand-image";
                uploadFileName=  await uploadBase64Image(filePath,imageType,files);
            }
    
            const payload = {
                category_id: categoryId,
                sub_category_id: SubcategoryId,
                brand_id: BrandId,
                brand_name: BrandName,
                brand_image: uploadFileName,
                onelinedescription: data.one_line_desc,
                single_click_amount:couponPrice,
                amount: data.amount,
                gst: data.gst,
                total_amount: data.total_amount,
                description: data.description,
                websitelink: data.website_url,
                total_click: data.total_click,
                tutorial: data.tutorial,
                start_date: data.start_date,
                discount: data.discount,
            };
    
            const razorpayPayload = {
                amount: data.total_amount * 100,
                currency: "INR",
                email: loginUserData?.email,
                product_name: BrandName,
                success_url: `${process.env.REACT_APP_PUBLIC_URL}/manage-advertisement/stripe-success/coupon-add`,
                cancel_url: `${process.env.REACT_APP_PUBLIC_URL}/manage-advertisement/stripe-cancel/coupon-add`,
            };
    
            const razorpayResponse = await Razorpayoder(razorpayPayload);
    
            if (razorpayResponse.code === '1') {
                payload.razorpay_order_id = razorpayResponse.data.id;
    
                initPayment(razorpayResponse.data, payload);

            } else {
                TOAST_ERROR(razorpayResponse.message);
                setIsLoading(false);
            }
        } catch (error) {
          
            setIsLoading(false);
        }
    };
    
    const initPayment = (razorpayData, payload) => {

    const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: razorpayData.amount,
        currency: razorpayData.currency,
        order_id: razorpayData.id,
        handler: async (response) => {
            setIsLoading(true); 

            try {
                const verifyResponse = await RazorpayoderConfirm(response);
                const parsedResponse = verifyResponse && typeof verifyResponse === 'string' 
                    ? JSON.parse(verifyResponse)
                    : verifyResponse;

               
                
                setIsLoading(false); 

                   payload = {
                    ...payload,
                    razorpay_order_id: parsedResponse?.data?.order_id,
                    razorpay_payment_id: parsedResponse?.data?.payment_id,
                    razorpay_signature: parsedResponse?.data?.signature
                };

                if(verifyResponse.code === '1'){

                    const couponResponse = await createCoupon(payload);
                    if (couponResponse?.code === '1') {

                        TOAST_SUCCESS("Coupon created successfully!");
                        navigate('/manage-advertisement');
                        handleCloseCreateCouponModal()
    
                    } else {
                        TOAST_ERROR(couponResponse?.message || "Failed to create coupon.");
                    }
                }else{
                   TOAST_ERROR(verifyResponse?.message)
                }
              
            } catch (error) {
                console.error("Payment verification or coupon creation failed:", error);
                TOAST_ERROR("Payment verification failed.");
            } finally {
                setIsLoading(false); 
            }
        },
        theme: {
            color: "#3399cc",
        },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
};



    const handlePreSubmit = async () => {


        try {
            await trigger(); // Trigger validation for all fields
            if (Object.keys(errors).length === 0) {
                // Proceed with form submission if there are no errors
                const data = getValues(); // Get current form data

                // if (data.brand_image === null) {
                //     setError('brand_image', {
                //         type: 'manual',
                //         message: `Please select brand_iamge`
                //     });
                // }
                // else {
                onSubmit(data);
                // }
            }
        } catch (err) {
            
        }
    };

    const getCategoryList = () => {
        let payload = {}
        CategoryListDeatils(payload).then(function (response) {
            if (response.code === '1') {
                setCategories(response.data);
            } else if (response.code !== '0') {
                TOAST_ERROR(response.message);
            }else{
                TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }

    const getCouponPrice = () => {
        let payload = {};
        getCouponPriceAPI(payload).then(function (response) {
            if (response.code === '1') {
                setCouponPrice(response.data.price);
            } else if (response.code !== '0') {
                // TOAST_ERROR(response.message);
            }
        }).catch(function (error) {
            
        });
    }


    useEffect(() => {
        if (isModalOpen) {
            reset();
            getCategoryList();
            getCouponPrice();
        }
    }, [isModalOpen])

    useEffect(() => {
        const pathName = window.location.pathname;
        let pathArr = pathName.split('/');
        let newUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";

        if (pathArr[pathArr.length - 2] === 'stripe-success') {
            if (pathArr[pathArr.length - 1] === 'coupon-add') {
                let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
                dispatch(openAdsApprovalModal({ redirectUrl: redirectUrl, name: 'Coupon-add' }));
                window.history.replaceState(newUrl, '', newUrl);
            }
            else {
                let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
                dispatch(openAdsApprovalModal({ redirectUrl: redirectUrl, name: 'Coupon-add' }));
                window.history.replaceState(newUrl, '', newUrl);
            }
            window.history.pushState(null, null, newUrl);

            TOAST_SUCCESS('Payment created successfully');     
        }
        if (pathArr[pathArr.length - 2] === 'stripe-cancel') {
            let redirectUrl = process.env.REACT_APP_PUBLIC_URL + "manage-advertisement";
            SWAL_ALERT('Payment failed', 'error', true, null, redirectUrl);
            window.history.replaceState(newUrl, '', newUrl);
            window.history.pushState(null, null, newUrl);
        }
    }, []);


        function getCaterogyid(e){
            setCategoryId(e.target.value)
            setValue('subcategory','')
            setValue('brand','')
                SubCategoryList({category_id:e.target.value}).then(function (response) {
                    if (response.code === '1') {
                        setSubCategories(response.data);
                    } else if (response.code !== '0') {
                        // TOAST_ERROR(response.message);
                    }
                }).catch(function (error) {
                    
                });
        
        }
        
        function getSubCaterogyid(e){
            setSubCategoryId(e.target.value)
            setValue('brand','')
            
            FetchBrandList({category_id:categoryId ,sub_category_id:e.target.value}).then(function (response) {
                if (response.code === '1') {
                    setBrandListt(response.data);
                } else if (response.code !== '0') {
                    // TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
    
        }

        function getBrandid(e){
            const selectedBrandId = e.target.value;
            const selectedBrandName = e.target.options[e.target.selectedIndex].text;
            setBrandId(selectedBrandId);
            setBrandName(selectedBrandName)
        }


    return (

        <>
            <Modal show={isModalOpen} size="lg">
                <Modal.Body>
                    <button type="button" className="custom_btn" onClick={handleCloseCreateCouponModal}>X</button>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={`row`} >
                            <div className="col-md-12 mb-3">
                                <div className="slider_rutorial text-center">
                                    <h1>Create Coupon</h1>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("category", { required: "Please select category" })} autoComplete="off" onChange={(e) => getCaterogyid(e)}>
                                            <option value="" >Select Ad Category</option>
                                            {categories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.category?.message}</label>
                            </div>
                            
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("subcategory", { required: "Please select sub category" })} autoComplete="off" onChange={(e) => getSubCaterogyid(e)}>
                                            <option value="" >Select Sub Category</option>
                                            {Subcategories && Subcategories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.subcategory?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <div className='select_div'>
                                        <select className="form-select" {...register("brand", { required: "Please select brand" })} autoComplete="off" onChange={(e) => getBrandid(e)} >
                                            <option value="" >Select Brand</option>
                                            {Brandlistt && Brandlistt.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label className="error">{errors.brand?.message}</label>
                            </div>

                            {/* <div className="col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group  w-100">
                                <input type="text" className="form-control" {...register("company_name", { required: false, pattern: { value: /^[a-zA-Z]*$/, message: "Please enter only letters (A-Z, a-z) with no special characters or numbers" }, maxLength: { value: 100, message: "Company name must not exceed 100 characters" } })} placeholder="Company Name" />
                            </div>
                            <label className="error">{errors.company_name?.message}</label>
                        </div> */}

 
                            <div className={`col-md-12 col-12 mb-3 `} >
                                <div className="upload_image_video">
                                    <Controller name="brand_image" control={control} defaultValue={null} render={({ field }) => (
                                        <>
                                            <input type="file" accept='image/*' onChange={handleFileInput} />
                                        </>
                                    )} />
                                    <h6>Upload Brand Image</h6>
                                    <span className="error">Note:For brand image resolution of 600X250(Aspected Ratio-: 12:5) is suggested (Maximum File Size Limit:5 MB)</span>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/image.svg"} alt='' />
                                    <img src={croppedImage} alt='' height={'150px'} className={selectedFile === null ? 'd-none' : ''} />
                                </div>
                                <label className="error">{errors.brand_image?.message}</label>
                            </div>



                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Website URL" {...register("website_url", { required: "Please enter website URL", pattern: { value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/, message: "Please enter a valid website URL" } })} autoComplete="off" />
                                </div>
                                <label className="error">{errors.website_url?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" {...register("one_line_desc", { required: "Please enter one line description", maxLength: { value: 50, message: "One line description must not exceed 50 characters" } })} placeholder="One line description" />
                                </div>
                                <label className="error">{errors.one_line_desc?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" maxLength="10" {...register("discount", { required: "Please enter discount", maxLength: { value: 10, message: "Discount must not exceed 10 characters" } })} placeholder="Discount" />
                                </div>
                                <label className="error">{errors.discount?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <textarea className="form-control" {...register("description", {
                                        required: "Please enter description",
                                        // pattern: { value: /^[a-zA-Z0-9. \n\uD83C-\uDBFF\uDC00-\uDFFF]*$/, message: "Please enter only letters, numbers, emojis & spaces with no special characters" },
                                        maxLength: { value: 1000, message: "Description must not exceed 1000 characters" }
                                    })} autoComplete="off" placeholder="Description"></textarea>
                                </div>
                                <label className="error">{errors.description?.message}</label>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <textarea className="form-control" {...register("tutorial", {
                                        required: "Please enter full description",
                                        // pattern: { value: /^[a-zA-Z0-9. \n\uD83C-\uDBFF\uDC00-\uDFFF]*$/, message: "Please enter only letters, numbers, emojis & spaces with no special characters" },
                                        // maxLength: { value: 1000, message: "Tutorial must not exceed 1000 characters" }
                                    })} autoComplete="off" placeholder="Full Description"></textarea>
                                </div>
                                <label className="error">{errors.tutorial?.message}</label>
                            </div>

                            <div className="col-md-12 mb-3 ">
                                <div className="form-group fixed_icon w-100">
                                    <DatePicker customInput={<input className="form-control" {...register("start_date", { required: "Please select start date" })} />} wrapperClassName="custom-datepicker-wrapper" dateFormat="dd/MM/yyyy" placeholderText="Select start date"
                                        selected={getValues('start_date')} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={100}
                                        onChange={(date) => setValue('start_date', date, { shouldValidate: true })} minDate={getMinDate()} autoComplete="off" />
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "assets/images/icon/calendar.svg"} className="front_icon logo_1" />
                                </div>
                                <label className="error">{errors.start_date?.message}</label>
                            </div>


                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group w-100">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Total Click"
                                        {...register("total_click", {
                                            required: "Please enter total click",
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: "Please enter only numbers",
                                            },
                                            maxLength: {
                                                value: 5,
                                                message: "Maximum length is 5 characters",
                                            },
                                        })}
                                        autoComplete="off"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "").slice(0, 5); // Allow only digits (numbers) and limit to 5 characters
                                        }}
                                        onBlur={(e) => {
                                            handletotalclick(e); // Call your function to update other fields on focus out
                                        }}
                                    />
                                </div>
                                <label className="error">{errors.total_click?.message}</label>
                            </div>



                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Amount" {...register("amount", {
                                        required: "Please enter amount", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.amount?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control"  placeholder="GST (18%)" {...register("gst", {
                                        required: "Please enter GST", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.gst?.message}</label>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                <div className="form-group  w-100">
                                    <input type="text" className="form-control" placeholder="Total Amount" {...register("total_amount", {
                                        required: "Please enter total amount", pattern: { value: /^[0-9].*$/, message: "Please enter only numbers" }
                                    })} readOnly autoComplete="off" />
                                </div>
                                <label className="error">{errors.gst?.message}</label>
                            </div>
                            
                            <div className="col-md-12 mb-2">
                                {/* <button type="submit" className="btn btn-theme mt-auto w-100">Done</button> */}
                                <button type="button" className="btn btn-theme mt-auto w-100" onClick={handlePreSubmit}>Done</button>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={isCropperOpen}>
                <Modal.Body>
                    <button type="button" className="custom_btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseImageCropper}>X</button>


                    <Cropper
                        ref={cropperRef}
                        src={selectedFile}
                        aspectRatio={5 / 2}
                        guides={false}
                        dragMode="move" // Enable dragging of the crop box
                        cropBoxMovable={true} // Allow the crop box to be moved
                        cropBoxResizable={false} // Prevent resizing of the crop box
                        cropend={() => {
                            // Optional: Get cropper instance
                            setCropperInstance(cropperRef.current.cropper);
                        }}
                    />

                    <div className="col-md-12 mb-5 mt-5">
                        <button onClick={handleCrop} className="btn btn-theme mt-auto w-100">Crop Image</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Loader isLoading={isLoading} />
        </>
    );
};

export default CreateCouponModal;
