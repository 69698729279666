
import { ImageUpload } from '../utils/api.service';
import axios from "axios";


export const uploadBase64Image = async (foldername, file_type,files) => {

  
  try {
    const response = await ImageUpload({
      bucketFolderName: foldername,
      file_type: file_type,
      file_name: files.name,
    });

    if (response?.data) {

const { data: signedUrl } = response.data;

      await axios.put(signedUrl, files, {
        headers: {
          "Content-Type": file_type,
        },
      });
      return response.data.filename;
    } else {
      throw new Error('Invalid response data for S3 upload link');
    }
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
};
