import { combineReducers } from "@reduxjs/toolkit";
import modalTutorialReducer from "./slices/modalTutorialSlice";
import modalLoginReducer from "./slices/modalLoginSlice";
import modalContactReducer from "./slices/modalContactSlice";
import modalSignupReducer from "./slices/modalSignupSlice";
import modalOTPReducer from "./slices/modalOTPSlice";
import authUserReducer from "./slices/authUserSlice";
import editProfileReducer from "./slices/editProfileSlice";
import imageCropperReducer from "./slices/imageCropperSlice";
import forgotPasswordReducer from "./slices/forgotPasswordSlice";
import resetPasswordReducer from "./slices/resetPasswordSlice";
import adsReducer from "./slices/adsSlice";
import commentReducer from "./slices/commentSlice";
import claimReducer from "./slices/claimSlice";
import watchReducer from "./slices/watchSlice";
import referFriendReducer from "./slices/referFriendSlice";
import createAdsReducer from "./slices/createAdsSlice";
import adsApproval from "./slices/adsApprovalSlice";
import modalCommentCoupon from "./slices/commentCouponSlice";
import reportCoupon from "./slices/reportCouponSlice";
import CreateCoupon from "./slices/createCouponSlice";
import ReCaptcha from "./slices/modalreCaptchaSlice";
import commentAd from "./slices/commentAdSlice";
import imageCropperCouponSlice from "./slices/imageCropperCouponSlice";
import couponSearchSlice from "./slices/couponSearchSlice";
import themeSlice from "./slices/themeSlice";

const rootReducer = combineReducers({
  modalTutorial: modalTutorialReducer,
  modalLogin: modalLoginReducer,
  modalContact: modalContactReducer,
  modalSignup: modalSignupReducer,
  modalOTP: modalOTPReducer,
  authUser: authUserReducer,
  editProfile: editProfileReducer,
  imageCropper: imageCropperReducer,
  modalForgotPassword: forgotPasswordReducer,
  modalResetPassword: resetPasswordReducer,
  modalAds: adsReducer,
  modalComment: commentReducer,
  modalClaim: claimReducer,
  modalWatch: watchReducer,
  modalReferFriend: referFriendReducer,
  modalCreateAds: createAdsReducer,
  modalAdsApproval: adsApproval,
  modalCommentCoupon: modalCommentCoupon,
  reportCoupon: reportCoupon,
  CreateCoupon: CreateCoupon,
  ReCaptcha: ReCaptcha,
  commentAd: commentAd,
  imageCropperCoupon: imageCropperCouponSlice,
  couponsearchtoggle: couponSearchSlice,
  theme: themeSlice,
});

export default rootReducer;
