import React, { useEffect, useState } from 'react';
import { getPages, getFAQs } from '../../utils/api.service';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TOAST_ERROR } from '../../utils/common.service';
import styles from './StaticPage.module.css';
import { useSelector } from 'react-redux';
import Layout from '../../layouts/Layout';

const StaticPage = ({ slug }) => {

    const isLoggedin = useSelector((state) => state.authUser.isLoggedin);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);

    const handleAccordionClick = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const fetchStaticContent = (slug) => {
        if (slug === "faqs") {
            let payload = {};
            getFAQs(payload).then(function (response) {
                if (response.code === '1') {
                    setFaqs(response.data);
                    setTitle('FAQs');
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    
        else {
            let payload = {};
            getPages(payload).then(function (response) {
              
                
                if (response.code === '1') {
                    response.data.map((item, index) => {
                        if (item.alies == slug) {
                            setContent(item.content);
                            setTitle(item.page_name);
                        }
                    })
                } else if (response.code !== '0') {
                    TOAST_ERROR(response.message);
                }
            }).catch(function (error) {
                
            });
        }
    }

    useEffect(() => {
        fetchStaticContent(slug)
    }, [slug])

    return (
        <Layout>
            <HelmetProvider>
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_NAME} | ${title}`}</title>
                    <body className="d-flex flex-column main-bg" />
                </Helmet>
            </HelmetProvider>

            {(slug === "faqs") ? (
                <section className={`winnig_section ${!isLoggedin ? styles.mt_100 : ''}`}>
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12 mb-5">
                                <div className="d-flex align-items-center">
                                    <div className="ad_name">
                                        <h4>{title}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-column p-4">
                            {/* <div className="row"> */}
                                {faqs.map((faq, index) => (
                                    <div key={index} className="row">

                                        <button className={styles.accordion} onClick={() => handleAccordionClick(index)}>{faq.question} {openIndex === index ? <span>-</span> : <span>+</span>}</button>
                                        <div className={styles.panel + ' mb-3'}>
                                            {openIndex === index && (
                                                <p className='m-3' dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                            )}
                                        </div>

                                    </div>
                                ))}
                            {/* </div> */}
                        </div>

                    </div>
                </section>
            ) : (
                <section className={`winnig_section ${!isLoggedin ? styles.mt_100 : ''}`}>
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12 mb-5">
                                <div className="d-flex align-items-center">
                                    <div className="ad_name">
                                        <h4>{title}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.impression_main} flex-column p-4`}>
                            <div className="" style={{    color: '#0E1013'}}>
                                <p className='m-3' dangerouslySetInnerHTML={{ __html: content }}></p>
                            </div>
                        </div>

                    </div>
                </section>
            )}

        </Layout>
    )
}

export default StaticPage;
