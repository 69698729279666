import axios from "axios";
import { bodyEncryption, bodyDecryption, TOAST_ERROR, removeAuthData } from './common.service';
import Cookies from 'js-cookie';


const AxiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL1,
    headers: {
        'api-key': bodyEncryption(process.env.REACT_APP_API_KEY, false),
        'Content-Type': 'text/plain',
        'accept-language': 'en',
        'p-type': 'W'
    }
});

AxiosClient.interceptors.request.use(function (config) {
    const encAuthUserData = Cookies.get('onestens-auth-user-data');
    if (encAuthUserData !== undefined) {
        const authUserData = JSON.parse(bodyDecryption(encAuthUserData));
        // 
        if (authUserData !== undefined && authUserData.token !== undefined) {
            config.headers['token'] = bodyEncryption(authUserData.token, false)
        }
    }
    return config;
});



AxiosClient.interceptors.response.use(
    function (response) {
        let decData = bodyDecryption(response.data);
        decData = JSON.parse(decData);
        
        if (decData.code === '0') {
            // TOAST_ERROR(decData.message);                                                                    
            return decData;
        } else {
            return decData;
        }
    },
    function (error) {
        // 
        let res = error.response;
        if (res.status === 401) {
            removeAuthData();
            window.location.reload();
        }
        
        return Promise.reject(error);
    }
);

export default AxiosClient;